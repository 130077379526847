import { format, getYear, parseISO } from 'date-fns';
import { isPlainObject } from 'lodash';
import {
  ADD_SKILL,
  DELETE_SKILL,
  GET_CANDIDATE_OBJECT,
  SAVE_EDUCATION_CANDIDATE_OBJECT,
  SAVE_EXPERIENCE_CANDIDATE_OBJECT,
  UPDATE_CANDIDATE_OBJECT,
  UPDATE_EXPERIENCE_CANDIDATE_OBJECT,
  UPDATE_FIRST_LEVEL_VALUES,
  UPDATE_SKILL,
  UPDATE_LANGUAGE,
  UPDATE_CANDIDATE_PROFILE_PIC,
  UPDATE_VISA,
  GET_CANDIDATE_OBJECT_STEP_4,
} from '../actionTypes';
import CandidateApi from '../../api/CandidateApi';
import { ajaxCallError } from './ajaxStatusActions';
import { dateDiffInYearsAndMonths } from '../../helpers/dateHelper';
import { CANDIDATE_STATUS_CONST } from '../../common/constant';
import candidateSource from '../../config/CandidateConfig';

const formatEducationObject = (candidateEducationObjs) => {
  const educationArray = [];
  candidateEducationObjs.forEach((item) => {
    const data = {
      degree: item.degree,
      endYear:
        item.endYear !== '' ? format(parseISO(item.endYear), 'yyyy') : '',
      startYear:
        item.startYear !== '' ? format(parseISO(item.startYear), 'yyyy') : '',
      institute: item.institute,
      fieldOfStudy: item.fieldOfStudy,
      duration:
        item.startYear !== '' && item.endYear !== ''
          ? getYear(new Date(item.endYear)) - getYear(new Date(item.startYear))
          : '',
      description: item.description !== undefined ? item.description : '',
    };
    educationArray.push(data);
  });

  educationArray.sort((a, b) => b.startDate - a.startDate);

  return educationArray;
};

const formatExperienceObject = (candidateExperienceObjs, trans) => {
  const experienceArray = [];
  candidateExperienceObjs.forEach((item) => {
    const data = {
      company: item.company,
      country: {
        id: item.country?.id,
        label:
          item.country?.label !== undefined ? item.country?.label : undefined,
      },
      startDate: item.startDate === '' ? '' : parseISO(item.startDate),
      endDate: item.endDate === '' ? '' : parseISO(item.endDate),
      title: item.title,
      currentPosition: item.currentPosition,
      duration:
        // eslint-disable-next-line no-nested-ternary
        item.endDate !== '' && item.startDate !== ''
          ? item.currentPosition
            ? dateDiffInYearsAndMonths(item.startDate, new Date(), trans)
            : dateDiffInYearsAndMonths(item.startDate, item.endDate, trans)
          : '',
      description: item.description !== undefined ? item.description : '',
      industry: {
        id: isPlainObject(item.industry) ? item.industry.id : '',
        label: isPlainObject(item.industry) ? item.industry.label : '',
      },
    };
    // There is a unknown issue with object reference. So to avoid the object ref by json stringify and parse
    try {
      experienceArray.push(JSON.parse(JSON.stringify(data)));
    } catch (e) {
      experienceArray.push(data);
    }
  });

  experienceArray.sort((a, b) => b.startDate - a.startDate);
  return experienceArray;
};

const getCandidateObject = (payload, trans) => async (dispatch) => {
  let candidateObject = {};

  if (!payload.id && payload.createMode) {
    candidateObject = {
      data: {
        firstName: '',
        lastName: '',
        resumePath: null,
        profilePic: null,
        jobTitleLookingFor: [],
        experience: [],
        education: [],
        skills: [],
        languages: [],
        expectedSalary: null,
        typeOfEmployment: [],
        candidateStatus: [],
        noticePeriod: {},
        preferredLocations: [],
        currentLocation: {},
        citizenship: {},
        visibility: true,
        sendSetPasswordEmail: false,
        status: CANDIDATE_STATUS_CONST.ACTIVE, // noted
        tag: [],
        source: { option: null, otherText: '' },
        currentSalary: '',
        reasonForLeaving: '',
        lookingFor: '',
        owner: null,
        workingRemotely: false,
      },
    };
  } else {
    candidateObject = await CandidateApi.getExperienceAndEducation(payload);
  }

  if (
    candidateObject.data.tag === undefined ||
    candidateObject.data.tag.length === 0
  ) {
    candidateObject.data.tag = [];
  }

  if (
    candidateObject.data.reasonForLeaving === undefined ||
    candidateObject.data.reasonForLeaving === null
  ) {
    candidateObject.data.reasonForLeaving = '';
  }

  if (
    candidateObject.data.lookingFor === undefined ||
    candidateObject.data.lookingFor === null
  ) {
    candidateObject.data.lookingFor = '';
  }

  if (
    candidateObject.data.currentSalary === undefined ||
    candidateObject.data.currentSalary === null
  ) {
    candidateObject.data.currentSalary = '';
  }

  if (
    candidateObject.data.owner === undefined ||
    !candidateObject.data.owner ||
    candidateObject.data.owner.length === 0
  ) {
    candidateObject.data.owner = {};
  }

  if (
    candidateObject.data.source === undefined ||
    !candidateObject.data.source ||
    candidateObject.data.source.length === 0
  ) {
    candidateObject.data.source = {};
  }

  if (
    candidateObject.data.education !== undefined &&
    candidateObject.data.education.length !== 0
  ) {
    candidateObject.data.education = formatEducationObject(
      candidateObject.data.education
    );
  } else {
    candidateObject.data.education = [];
  }

  if (
    candidateObject.data.experience !== undefined &&
    candidateObject.data.experience.length !== 0
  ) {
    candidateObject.data.experience = formatExperienceObject(
      candidateObject.data.experience,
      trans
    );
  } else {
    candidateObject.data.experience = [];
  }

  candidateObject.data.currentExperienceObject = {
    id: 0,
    title: '',
    company: '',
    country: { id: 0, label: '' },
    startDate: new Date(),
    endDate: new Date(),
    fieldOfStudy: '',
    description: '',
    industry: { id: 0, label: '' },
  };
  /* eslint-disable no-param-reassign */
  candidateObject.data.experienceDialogStatus = false;

  dispatch({
    type: GET_CANDIDATE_OBJECT_STEP_4,
    payload: candidateObject.data,
  });
};

const getAndStoreCandidateObject = (cusConfig) => async (dispatch) => {
  try {
    const candidate = await CandidateApi.getCandidate('', cusConfig);

    dispatch({
      type: GET_CANDIDATE_OBJECT,
      payload: candidate.data,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

const successCandidateFetch = (candidate) => {
  return {
    type: GET_CANDIDATE_OBJECT,
    payload: candidate,
  };
};

const addToJsonWithoutFormatting =
  (object, data, jsonArrayName) => async (dispatch) => {
    object.data[jsonArrayName].push(data);
    dispatch({
      type: ADD_SKILL,
      payload: object.data,
    });
  };

const deleteWithoutFormatting =
  (id, object, jsonArrayName) => async (dispatch) => {
    object.data[jsonArrayName].splice(id, 1);
    dispatch({
      type: DELETE_SKILL,
      payload: object.data,
    });
  };

const updateSkill = (id, boolValue, object) => async (dispatch) => {
  object.data.skills[id].isStar = boolValue;

  dispatch({
    type: UPDATE_SKILL,
    payload: object.data,
  });
};

const updateSkillBySkillId = (id, boolValue, object) => async (dispatch) => {
  object.data.skills.map((el) => {
    if (el.skillId === id) {
      el.isStar = boolValue;
    }

    return el;
  });

  dispatch({
    type: UPDATE_SKILL,
    payload: object.data,
  });
};

const deleteSkill = (id, object) => async (dispatch) => {
  const remainingSkills = object.data.skills.filter((el) => el.skillId !== id);
  object.data.skills = remainingSkills;

  dispatch({
    type: DELETE_SKILL,
    payload: object.data,
  });
};

const updateLanguages = (id, value, object) => async (dispatch) => {
  object.data.languages[id].level = value;
  dispatch({
    type: UPDATE_LANGUAGE,
    payload: object.data,
  });
};

const updateProfilePic = (value, object) => async (dispatch) => {
  object.data.profilePic = value;
  dispatch({
    type: UPDATE_CANDIDATE_PROFILE_PIC,
    payload: object.data,
  });
};

const updateVisaType = (id, object) => async (dispatch) => {
  object.data.preferredLocations[id].visaType =
    !object.data.preferredLocations[id].visaType;
  dispatch({
    type: UPDATE_VISA,
    payload: object.data,
  });
};

const updateVisaTypeV2 = (id, object, data) => async (dispatch) => {
  object.data.preferredLocations[id].visaType = data.visaType;
  object.data.preferredLocations[id].visaCategory = data.visaCategory;
  dispatch({
    type: UPDATE_VISA,
    payload: object.data,
  });
};

const updateFirstLevelValues = (name, value, object) => async (dispatch) => {
  object.data[name] = value;
  dispatch({
    type: UPDATE_FIRST_LEVEL_VALUES,
    payload: object.data,
  });
};

const updateNoticePeriodRadios = (value, object) => async (dispatch) => {
  object.data.noticePeriod.type = value;
  if (value === 0) {
    object.data.noticePeriod.option = 0;
  } else {
    object.data.noticePeriod.option = undefined;
  }

  dispatch({
    type: UPDATE_FIRST_LEVEL_VALUES,
    payload: object.data,
  });
};

const updateWorkingRemotelyRadios = (value, object) => async (dispatch) => {
  if (value === 'true') {
    object.data.workingRemotely = true;
  } else if (value === 'false') {
    object.data.workingRemotely = false;
  } else {
    object.data.workingRemotely = undefined;
  }

  dispatch({
    type: UPDATE_FIRST_LEVEL_VALUES,
    payload: object.data,
  });
};

const updateSource = (value, object) => async (dispatch) => {
  object.data.source.option = value;

  if (candidateSource.other.option !== value) {
    object.data.source.otherText = '';
  }

  dispatch({
    type: UPDATE_FIRST_LEVEL_VALUES,
    payload: object.data,
  });
};

const updateSourceOtherText = (value, object) => async (dispatch) => {
  object.data.source.otherText = value;

  dispatch({
    type: UPDATE_FIRST_LEVEL_VALUES,
    payload: object.data,
  });
};

const updateNoticePeriodOption = (value, object) => async (dispatch) => {
  object.data.noticePeriod.option = value;
  dispatch({
    type: UPDATE_FIRST_LEVEL_VALUES,
    payload: object.data,
  });
};

const updateJobCheckBox = (value, object) => async (dispatch) => {
  if (object.data.typeOfEmployment.includes(value)) {
    const index = object.data.typeOfEmployment.indexOf(value);
    object.data.typeOfEmployment.splice(index, 1);
  } else {
    object.data.typeOfEmployment.push(value);
  }

  dispatch({
    type: UPDATE_FIRST_LEVEL_VALUES,
    payload: object.data,
  });
};

const updateEducationObject = (payload, mainObject) => async (dispatch) => {
  /* eslint-disable no-param-reassign */
  const {
    degree,
    fieldOfStudy,
    institute,
    startYear,
    endYear,
    id,
    description,
  } = payload;
  mainObject.data.education[id] = {
    degree,
    endYear: format(new Date(endYear), 'yyyy'),
    fieldOfStudy,
    institute,
    startYear: format(new Date(startYear), 'yyyy'),
    duration: getYear(new Date(endYear)) - getYear(new Date(startYear)),
    description: description !== undefined ? description : '',
  };

  mainObject.data.education.sort((a, b) => {
    return b.startYear - a.startYear;
  });

  dispatch({
    type: UPDATE_CANDIDATE_OBJECT,
    payload: mainObject.data,
  });
};

const updateExperienceCurrentPosition =
  (item) => async (dispatch, getState) => {
    const { candidates } = getState();
    const experienceObj = candidates?.data?.experience;

    if (experienceObj?.[item.id]) {
      experienceObj[item.id].currentPosition = item.currentPosition;
      dispatch({
        type: UPDATE_CANDIDATE_OBJECT,
        payload: candidates.data,
      });
    }
  };

const saveEducationObject = (payload, mainObject) => async (dispatch) => {
  const { degree, fieldOfStudy, institute, startYear, endYear, description } =
    payload;
  mainObject.data.education.push({
    degree,
    endYear: format(new Date(endYear), 'yyyy'),
    fieldOfStudy,
    institute,
    startYear: format(new Date(startYear), 'yyyy'),
    duration: getYear(new Date(endYear)) - getYear(new Date(startYear)),
    description: description !== undefined ? description : '',
  });

  mainObject.data.education.sort((a, b) => {
    return b.startYear - a.startYear;
  });

  dispatch({
    type: SAVE_EDUCATION_CANDIDATE_OBJECT,
    payload: mainObject.data,
  });
};

const saveExperienceObject =
  (payload, mainObject, trans) => async (dispatch) => {
    const {
      title,
      company,
      country,
      startDate,
      endDate,
      description,
      industry,
      currentPosition,
    } = payload;
    mainObject.data.experience.push({
      title,
      company,
      country,
      currentPosition,
      startDate,
      endDate: currentPosition ? new Date() : endDate,
      duration: currentPosition
        ? dateDiffInYearsAndMonths(startDate, new Date(), trans)
        : dateDiffInYearsAndMonths(startDate, endDate, trans),
      description: description !== undefined ? description : '',
      industry: industry !== undefined ? industry : {},
    });

    mainObject.data.education.sort((a, b) => {
      return b.startDate - a.startDate;
    });

    mainObject.data.experience.sort((a, b) => {
      return b.startDate - a.startDate;
    });

    dispatch({
      type: SAVE_EXPERIENCE_CANDIDATE_OBJECT,
      payload: mainObject.data,
    });
  };

const onExperienceUpdate = (payload, mainObject, trans) => async (dispatch) => {
  const {
    title,
    company,
    country,
    startDate,
    endDate,
    id,
    description,
    industry,
    currentPosition,
  } = payload;
  mainObject.data.experience[id] = {
    title,
    company,
    country,
    currentPosition,
    startDate,
    endDate: currentPosition ? new Date() : endDate,
    duration: currentPosition
      ? dateDiffInYearsAndMonths(startDate, new Date(), trans)
      : dateDiffInYearsAndMonths(startDate, endDate, trans),
    description: description !== undefined ? description : '',
    industry: industry !== undefined ? industry : {},
  };

  mainObject.data.education.sort((a, b) => {
    return b.startDate - a.startDate;
  });

  mainObject.data.experience.sort((a, b) => {
    return b.startDate - a.startDate;
  });

  dispatch({
    type: UPDATE_EXPERIENCE_CANDIDATE_OBJECT,
    payload: mainObject.data,
  });
};

export function cvData(CvData, trans) {
  return async (dispatch) => {
    try {
      const data = await CandidateApi.cvParserDataWithResume(CvData);
      const candidate = data.data;
      if (candidate?.education && candidate.education.length > 0) {
        candidate.education = formatEducationObject(candidate.education);
      } else {
        candidate.education = [];
      }

      if (candidate?.experience && candidate.experience.length > 0) {
        candidate.experience = formatExperienceObject(
          candidate.experience,
          trans
        );
      } else {
        candidate.experience = [];
      }
      candidate.tag = [];
      candidate.source = { option: null, otherText: '' };
      candidate.currentSalary = '';
      candidate.reasonForLeaving = '';
      candidate.lookingFor = '';
      candidate.owner = {};

      dispatch({
        type: GET_CANDIDATE_OBJECT,
        payload: candidate,
      });
    } catch (error) {
      if (error) {
        // eslint-disable-next-line no-throw-literal
        throw error?.response?.data
          ? [
              {
                key: 'error',
                message: error.response.data.errors
                  ? error.response.data.errors[0].message
                  : 'errors.internalServerError',
              },
            ]
          : [{ key: 'error', message: 'errors.internalServerError' }];
      } else {
        dispatch(ajaxCallError());
      }
    }
  };
}

export {
  getAndStoreCandidateObject,
  getCandidateObject,
  updateEducationObject,
  saveEducationObject,
  saveExperienceObject,
  onExperienceUpdate,
  updateSkill,
  updateSkillBySkillId,
  deleteSkill,
  updateLanguages,
  addToJsonWithoutFormatting,
  deleteWithoutFormatting,
  updateFirstLevelValues,
  updateJobCheckBox,
  updateNoticePeriodRadios,
  updateWorkingRemotelyRadios,
  updateNoticePeriodOption,
  updateVisaType,
  updateVisaTypeV2,
  updateExperienceCurrentPosition,
  successCandidateFetch,
  updateProfilePic,
  updateSource,
  updateSourceOtherText,
};
