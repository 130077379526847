import { LOCATION_CHANGE } from 'connected-react-router';
import {
  ADD_LANGUAGE,
  ADD_SKILL,
  DELETE_EDUCATION,
  DELETE_EXPERIENCE,
  UPDATE_LANGUAGE,
  DELETE_LANGUAGE,
  DELETE_SKILL,
  GET_CANDIDATE_OBJECT,
  GET_CANDIDATE_OBJECT_STEP_4,
  SAVE_EDUCATION_CANDIDATE_OBJECT,
  SAVE_EXPERIENCE_CANDIDATE_OBJECT,
  UPDATE_CANDIDATE_OBJECT,
  UPDATE_EXPERIENCE_CANDIDATE_OBJECT,
  UPDATE_FIRST_LEVEL_VALUES,
  UPDATE_SKILL,
  UPDATE_CANDIDATE_PROFILE_PIC,
  UPDATE_VISA,
  LOGOUT_SUCCESS,
  REMOVE_CANDIDATE_PROFILE,
} from '../actionTypes';
import { candidateObject } from './initialState';

const candidateReducer = (state = candidateObject, action) => {
  switch (action.type) {
    case GET_CANDIDATE_OBJECT:
    case GET_CANDIDATE_OBJECT_STEP_4:
    case UPDATE_CANDIDATE_OBJECT:
    case SAVE_EDUCATION_CANDIDATE_OBJECT:
    case SAVE_EXPERIENCE_CANDIDATE_OBJECT:
    case UPDATE_EXPERIENCE_CANDIDATE_OBJECT:
    case DELETE_EXPERIENCE:
    case DELETE_EDUCATION:
    case DELETE_SKILL:
    case UPDATE_SKILL:
    case UPDATE_CANDIDATE_PROFILE_PIC:
    case ADD_SKILL:
    case DELETE_LANGUAGE:
    case ADD_LANGUAGE:
    case UPDATE_LANGUAGE:
    case UPDATE_FIRST_LEVEL_VALUES:
    case UPDATE_VISA:
      // try to use spread operators here.
      return { ...state, data: action.payload };
    case LOGOUT_SUCCESS:
      return candidateObject;
    case LOCATION_CHANGE:
      return state;
    case REMOVE_CANDIDATE_PROFILE:
      return { ...state, data: {} };
    default:
      return state;
  }
};

export default candidateReducer;
